import React from 'react';

function OpenHoursBox(props) {
    return (
        <div className="rn-address bg_color--3">
            <div className="inner d-flex justify-content-center align-items-center flex-column">

                <h4 className="title text-center d-inline-block mt--15">Urnik ogledov Rudnika Sitarjevec Litija</h4>
                <p className="mt--15 text-center">Ogledi možni vse dni v tednu po predhodni najavi in za minimalno štiri obiskovalce.</p>
                <small className="text-center mt--30 saturday-special-offer"><strong>Vsako soboto ob 10:00 možnost ogleda Izvoznega rova rudnika brez predhodne najave.</strong></small>
                <small className="text-center mt--30">Potrebno je predhodno naročilo.</small>
            </div>
        </div>
    );
}

export default OpenHoursBox;